
import { defineComponent, ref, watch, onMounted } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiBase, ApiMerchant } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";

export default defineComponent({
  name: "add-related-relationship-modal",
  props: {
    id: { type: Number, default: 0 },
    merchantId: { type: Number, default: 0 },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addRelationshipModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const merchantOptions = ref([]);
    const relationshipOptions = ref([]);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: props.id,
      merchant_id: props.merchantId,
      tag_short_key: "global_company_related_relationship",
      tag_value: "",
      related_merchant_id: "",
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          getFormData(newVal);
        } else {
          resetForm();
        }
      }
    );

    const rules = ref({
      related_merchant_id: [
        {
          required: true,
          message: "Parent Merchant is required",
          trigger: "change",
        },
      ],
      tag_value: [
        {
          required: true,
          message: "Relationship is required",
          trigger: "change",
        },
      ],
    });

    const getFormData = (id) => {
      if (id) {
        loading.value = true;
        ApiMerchant.getMerchantRelationshipInfo({ id: id })
          .then(({ data }) => {
            loading.value = false;
            if (data.code == 0) {
              let year = {
                fiscal_year: String(data.data.fiscal_year),
              };
              formData.value = { ...data.data, ...year };
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };
    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        merchantOptions.value = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getRelationshipOptions = () => {
      ApiBase.getTaggingData({
        short_key: "global_company_related_relationship",
      })
        .then(({ data }) => {
          if (data.code == 0) {
            relationshipOptions.value = data.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (props.id) {
            editRelationship();
          } else {
            addRelationship();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addRelationship = () => {
      ApiMerchant.addMerchantRelationship(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addRelationshipModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const editRelationship = () => {
      ApiMerchant.updateMerchantRelationship(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addRelationshipModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      hideModal(addRelationshipModalRef.value);
      resetForm();
      emit("reset-form");
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      modalHideListener(addRelationshipModalRef.value, () => {
        emit("reset-form");
      });
      getRelationshipOptions();
    });

    return {
      loading,
      merchantOptions,
      relationshipOptions,
      formData,
      rules,
      formRef,
      addRelationshipModalRef,
      submitButton,
      getFormData,
      submit,
      handleDiscard,
      resetForm,
      searchMerchantSourceItems,
    };
  },
});
